import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import React, { useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import moment from "moment";
import PerfectScrollbar from "react-perfect-scrollbar";
import { makeStyles } from "@material-ui/styles";
import Typography from "@material-ui/core/Typography";
import { useCubeQuery } from "@cubejs-client/react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Export from './Exportcsv'
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    padding: 0
  },
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1100
  },
  nameContainer: {
    display: "flex",
    alignItems: "baseline"
  },
  status: {
    marginRight: 15
  },
  actions: {
    justifyContent: "flex-end"
  },

   tableRow: {
       padding: '0 5px',
       cursor: "pointer",
       '.MuiTableRow-root.MuiTableRow-hover&:hover': {
       }
     },
     hoverable: {
       "&:hover": {
         cursor: `pointer`
       }
     },
     arrow: {
       fontSize: 10,
       position: "absolute"
     }
}));

const TableComponent = props => {

    const { className, sorting, setSorting, query, cubejsApi, ...rest } = props;

  const classes = useStyles();

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const tableHeaders = [
    { text: "Total Production", value: "Events.Production_Total" },
    { text: "Plastic Consumption", value: "Events.Plastic_Consumption" },   
    { text: "Number of Injections", value: "Events.Cycle_Count" },
    { text: "Device Name", value: "Device.devicename" },
    // { text: "Device Id", value: "Device.id" },
    { text: "Date", value: "Events.day" },
    { text: "Shift", value: "Events.Shift" }
   
  ];
  const { resultSet, error, isLoading } = useCubeQuery(query, { cubejsApi });
  if (isLoading) {
    return <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}><CircularProgress color="secondary" /></div>;
  }
  if (error) {
    return <pre>{error.toString()}</pre>;
  }
  if (resultSet) {
    let Production = resultSet.tablePivot();

    const handlePageChange = (event, page) => {
      setPage(page);
    };
    const handleRowsPerPageChange = event => {
      setRowsPerPage(event.target.value);
    };

    const handleSetSorting = str => {
              setSorting([str, sorting[1] === "desc" ? "asc" : "desc"]);
         };

    return (
      <Card
        {...rest}
        padding={"0"}
        className={clsx(classes.root, className)}
      >
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead className={classes.head}>
                  <TableRow>
                    {tableHeaders.map((item) => (
                      <TableCell key={item.value + Math.random()} 

                      onClick={() => {
                                        handleSetSorting(`${item.value}`);
                                     }}                                           
                               
                      >
                        <span>{item.text}</span>

                        <Typography
                          className={classes.arrow}
                        variant="body2"
                         component="span"
                       >
                         {(sorting[0] === item.value) ? (sorting[1] === "desc" ? <KeyboardArrowUpIcon/> :
                           <KeyboardArrowDownIcon/>) : null}
                      </Typography>

                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>

                  {Production.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(obj => (
                    <TableRow
                      className={classes.tableRow}
                      hover
                      key={obj["Event.Plastic_Consumption"]}
                    >
                      <TableCell>
                        {obj["Events.Production_Total"]}
                      </TableCell>
                      <TableCell>
                        {obj["Events.Plastic_Consumption"]}
                      </TableCell>
                      <TableCell>
                        {obj["Events.Cycle_Count"]}
                      </TableCell>                                   
                      
                      <TableCell>
                      {obj["Device.devicename"]}
                       </TableCell>                   
                      
                     
                       <TableCell>                       
                      {moment(obj["Events.day"]).format("DD-MM-YYYY")}   
                                                     
                   </TableCell> 

                      <TableCell>
                      {obj["Events.Shift"]}                       
                    </TableCell>    

                       
                                           
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>
        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={Production.length}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleRowsPerPageChange}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
          />
        </CardActions>
        <Export 
        data = {resultSet.tablePivot()}    
       />
      </Card>
    );
  } 
  
  
  else {
    return null
  }
};

TableComponent.propTypes = {
  className: PropTypes.string,
  query: PropTypes.object.isRequired
};

export default TableComponent;

