import logo from "./logo.svg";
import "./App.css";
import "./body.css";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { Layout } from "antd";
import cubejs from "@cubejs-client/core";
import { CubeProvider } from "@cubejs-client/react";
import Header from "./components/Header";
import WebSocketTransport from "@cubejs-client/ws-transport";
import { ApolloProvider } from "@apollo/react-hooks";
// import client from "./graphql/client";
//  const API_URL = "http://95.216.227.58:30089"
// const API_URL = "http://localhost:4000";

const API_URL = "https://prod-cubejs.tarf.io" 



const Auth_API = "https://auth.tarf.io/auth"


const queryString = require('query-string');


const parsed = queryString.parse(window.location.search);

  const cubejsApi = cubejs({
  transport: new WebSocketTransport({
    authorization: parsed.token,
    // authorization: CUBEJS_TOKEN,
    apiUrl: API_URL.replace("http", "ws")
  })
});



const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  }
}));


const AppLayout = ({ children }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Header />
      <div>{children}</div>
    </div>
  );
};

const App = ({ children }) => (
  <CubeProvider cubejsApi={cubejsApi}> 
      <AppLayout>{children}</AppLayout>
   </CubeProvider>
);

export default App;

// <ApolloProvider client={client}>
// </ApolloProvider>
