import React from "react";
import { makeStyles } from "@material-ui/styles";
import Table from "../components/Table.js";
import Toolbar from "../components/Toolbar.js";

const useStyles = makeStyles(theme => ({
  root: { padding: 10},
  content: {padding: 0, marginTop: 15 },
}));

const Dashboard = () => {
  const classes = useStyles();
  const tabs = ['Rasp-Pr-Counter', 'Counter2'];
  const [statusFilter, setStatusFilter] = React.useState(0);
  const [startDate, setStartDate] = React.useState(new Date("2021-01-01T00:00:00"));
 const [finishDate, setFinishDate] = React.useState(new Date("2022-03-01T00:00:00"));
//  const [priceFilter, setPriceFilter] = React.useState([0, 200]);
 const [sorting, setSorting] = React.useState(['Events.time', 'desc']);

  const query = {
   
    measures: [
          "Events.Production_Total",
          "Events.Plastic_Consumption",
          "Events.Cycle_Count"
        ],
        order: {
              [`${sorting[0]}`]: sorting[1]
       },
        
        dimensions: ["Device.devicename", 
        "Events.Shift", "Events.day"],

        timeDimensions: [
          {
            dimension: "Events.day",
            // dateRange: ['2021-03-12', '2021-03-31'],
            dateRange: [startDate, finishDate],
            granularity: "day"
          }
        ],  
       

          filters: [
                {
                 "dimension": "Device.devicename",
                 "operator": tabs[statusFilter] !== 'All' ? "equals" : "set",
                 "values": [
                   `${tabs[statusFilter]}`
                ]
              }
            ]
      
  };
  // <div className={classes.content}>
  // </div>

  return (
    <div className={classes.root}>
    <div className={classes.content}>  
    <Toolbar
       startDate={startDate}
       setStartDate={setStartDate}
       finishDate={finishDate}
       setFinishDate={setFinishDate}
      //  startDate={startDate}                   
      //  priceFilter={priceFilter}
      //  setPriceFilter={setPriceFilter}      
       statusFilter={statusFilter}
      setStatusFilter={setStatusFilter}
      tabs={tabs}
    />
    </div>
    
    <div className={classes.content}>
      <Table
          sorting={sorting}
          setSorting={setSorting}
        query={query}/>
    </div>
  </div>
  );
};

export default Dashboard;

